.o-solidPanel {
	$root: &;
	position: relative;
	overflow: hidden;
	border: 4px solid rgb(255, 255, 255);
	border-top-left-radius: rem(20);
	border-bottom-right-radius: rem(20);

	&-slight {
		#{$root}_wrapper {
			padding: rem(20) rem(16);
		}
	}

	// &_layer {
	//     position: absolute;
	//     top: -2px;
	//     left: -2px;
	//     right: -2px;
	//     bottom: -2px;
	//     background-color: rgba($deep-saffron, 0.8);
	//     border-top-left-radius: rem(20);
	//     border-bottom-right-radius: rem(20);
	//     mix-blend-mode: screen;
	// }

	&_layer {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: url("~assets/images/element/background-red.png") no-repeat
			center;
		background-size: 100% 100%;
	}

	&_wrapper {
		position: relative;
		z-index: 2;
		padding: rem(20) rem(30);
	}
}
