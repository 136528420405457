.p-login {
	&_banner {
		width: 100%;
		margin-right: auto;
		margin-left: auto;

		@include tab {
			width: 60%;
		}

		@include pcFixedContent {
			width: 30%;
		}
	}
}
