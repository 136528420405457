.o-modal {
	$root: &;
	$content: #{$root}_content;

	&_overlay {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9999;
		content: "";
		background: rgba(15, 39, 68, 0.85);
	}

	&_close {
		position: absolute;
		top: rem(10);
		right: rem(10);
		z-index: z("modal", "close");
		display: flex;
		cursor: pointer;

		.a-icon {
			z-index: 1;
		}
	}

	&_content {
		position: absolute;
		top: 50%;
		right: rem(30);
		left: rem(30);
		// min-height: 450px;
		max-height: 500px;
		padding: rem(28) rem(28) rem(32);
		margin: 0 auto;
		overflow: hidden auto;
		background-color: $COLOR-WHITE;
		border-radius: rem(20);
		outline: none;
		transform: translateY(-50%);
		appearance: none;

		@include pc {
			right: rem(90);
			left: rem(90);
			// max-height: 420px;
		}

		&-fullbox {
			padding: 0;
		}

		&-fixedheaderfooter {
			display: flex;
			flex-direction: column;
			padding: 0;
			overflow: hidden;
		}

		&-background-transparent {
			background-color: $COLOR-TRANSPARENT;
		}

		&-nonborder {
			border-radius: 0;
		}

		&-confirm {
			max-width: rem(550);
			max-height: rem(800);

			@include sp {
				.a-button_wrapper {
					font-size: rem(14);
				}
			}
		}

		&-wheels {}
	}

	&_header {
		position: relative;
		flex: 0 0 auto;
		padding: rem(20) rem(60);
		box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);

		.a-icon {
			position: absolute;
			top: 50%;
			right: 25px;
			transform: translateY(-50%);
		}
	}

	&_body {
		#{$content}-fixedheaderfooter & {
			flex: 1;
			padding: rem(32) rem(60);
			overflow: hidden auto;

			@include spSmall {
				padding: rem(20) rem(18) rem(40);
			}
		}
	}

	&_footer {
		flex: 0 0 auto;
		padding: rem(24) rem(60);
		box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.05);
	}
}

.reactmodal-body-open,
.reactmodal-html-open {
	overflow: hidden;
}