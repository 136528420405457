.a-icon {
	$root: &;
	display: inline-block;
	width: rem(26);
	height: rem(26);
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	$icons: "green-check-circle", "yellow-exclamation-triangle",
		"blue-info-circle", "aqua-dots", "aqua-large-spinner", "aqua-small-spinner",
		"red-exclamation-circle", "white-close-circle", "white-spinner",
		"close-circle-bg-gray", "grey-times", "clear", "logout";

	@each $icon in $icons {
		&-#{$icon} {
			background-image: url("~assets/images/icons/#{$icon}.svg");
		}
	}

	&-clickable {
		cursor: pointer;
		transition: opacity 0.3s ease-in-out;

		&:hover {
			opacity: 0.7;
		}
	}
}