/* @media
--------------------------------------------------------- */
// >= 1920
@mixin pc-large() {
	@media (min-width: $WIDTH-PC-LARGE) {
		@content;
	}
}

//  >= 1200
@mixin pcFixedContent() {
	@media (min-width: $WIDTH-PC) {
		@content;
	}
}

// >= 1366
@mixin pc-medium() {
	@media (min-width: $WIDTH-PC-MEDIUM) {
		@content;
	}
}

// >= 577
@mixin pc() {
	@media (min-width: $WIDTH-XS + 1px) {
		@content;
	}
}

// 577 <= x <= 1199
@mixin tab() {
	@media (min-width: $WIDTH-XS + 1) and (max-width: $WIDTH-PC - 1px) {
		@content;
	}
}

// <= 991
@mixin spMenu() {
	@media (max-width: $WIDTH-TAB - 1) {
		@content;
	}
}

// <= 768
@mixin spSmall() {
	@media (max-width: $WIDTH-SM) {
		@content;
	}
}

// <= 767
@mixin spSmallBoostrap() {
	@media (max-width: $WIDTH-SM - 1) {
		@content;
	}
}

// <= 576
@mixin sp() {
	@media (max-width: $WIDTH-XS) {
		@content;
	}
}

// <= 575
@mixin spBootstrap() {
	@media (max-width: $WIDTH-XS - 1) {
		@content;
	}
}

@mixin mobile-down {
	@media (max-width: 767px) {
		@content;
	}
}

/* Font-weight
--------------------------------------------------------- */

@mixin u-fw-regular {
	font-weight: 400;
}

@mixin u-fw-bold {
	font-weight: 700;
}

/* make area that keep the aspect-ratio of area. This should be used with background-image
--------------------------------------------------------- */
@mixin aspect-ratio($width: 1, $height: 1) {
	position: relative;

	&::before {
		display: block;
		padding-bottom: ($height / $width) * 100%;
		content: "";
	}
}

@mixin text-overflow($number: 2) {
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ($number);
}

@mixin align-middle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@mixin free-background {
	background-repeat: no-repeat;
	background-position: center;
	background-size: 100% 100%;
}

@mixin error-text {
	position: absolute;
	bottom: -20px;
	left: 0;
	margin-top: rem(5);
	font-size: rem(13);
	color: $COLOR-CINNABAR;
	white-space: nowrap;
}

@mixin adjust-flex($justify-content: center, $align-item: center) {
	display: flex;
	align-items: $align-item;
	justify-content: $justify-content;
}

@mixin font-base($font-size: 16, $line-height: 24) {
	font-size: rem($font-size);
	line-height: rem($line-height);
}
