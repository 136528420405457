.t-luckyWheel {
	position: relative;
	max-width: rem(360);
	padding-top: rem(60);
	padding-bottom: rem(24);
	margin-right: auto;
	margin-left: auto;

	&_circle {
		position: relative;
	}

	.pointer {
		position: absolute;
		top: 0;
		left: 50%;
		z-index: 1;
		transform: translateX(-50%);

		img {
			max-width: rem(42);
		}
	}

	.circle {
		position: absolute;
		top: 0;
		z-index: 2;
		mix-blend-mode: lighten;
	}

	img {
		width: 100%;
		max-height: auto;
	}

	&_modal {
		&_wrapper {
			padding: rem(10) 0;
		}

		&_content {
			margin: rem(40) 0;

			&-reward {
				margin: rem(12) 0;
			}
		}

		&_phoneCard {
			max-width: rem(309);
			margin: rem(40) auto rem(27.5) auto;

			@include mobile-down {
				max-width: rem(209);
			}
		}
	}

	&_button {
		max-width: rem(280);
		margin: auto;
		margin-top: rem(24);
		text-align: center;
	}
}
