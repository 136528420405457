.t-giftExchange {
	.w-50 {
		width: 50%;
	}
	.w-40 {
		width: 48%;
	}

	.w-60 {
		@media (min-width: 577px) {
			max-width: 48%;
		}
	}

	&_title {
		margin-top: rem(24);
		&-label {
			flex: 0 0 rem(189);
		}

		.w-100 {
			width: 100%;
		}

		.a-text {
			text-align: center;
		}
	}
}
