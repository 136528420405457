.customerInfo {
	&_btnOtp {
		button {
			padding: 0;
		}

		.a-button_wrapper {
			padding: rem(4);
		}
	}

	&_game {
		.a-pulldown {
			width: 100%;
		}
	}

	&_signImg {
		background-color: white;
		border-radius: rem(4);
	}

	&_signature {
		position: relative;
		width: 100%;
		height: rem(400);
		background-color: white;
		border-radius: rem(4);

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 80%;
			height: 80%;
			transform: translate(-50%, -50%);
			object-fit: contain;
			object-position: center;
		}

		&_clear {
			position: absolute;
			top: rem(8);
			right: rem(8);
			padding: rem(4);
		}

		&_confirm {
			position: absolute;
			right: rem(8);
			bottom: rem(8);
		}
	}

	&_logout {
		display: flex;
		justify-content: flex-end;
		padding: rem(8);

		.a-icon {
			width: 32px;
			height: 32px;
		}
	}
}