// FONT FAMILY: icielBrandonText
@font-face {
	font-family: "icielBrandonText";
	font-style: normal;
	font-weight: 700;
	src: url("~assets/fonts/icielBrandonText-Bold.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "Brandon Text";
	font-style: normal;
	font-weight: normal;
	src: url("~assets/fonts/BrandonTextF-Regular.woff2") format("woff2"),
		url("~assets/fonts/BrandonTextF-Regular.woff") format("woff"),
		url("~assets/fonts/BrandonTextF-Regular.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "Brandon Text";
	font-style: normal;
	font-weight: bold;
	src: url("~assets/fonts/BrandonTextF-Bold.woff2") format("woff2"),
		url("~assets/fonts/BrandonTextF-Bold.woff") format("woff"),
		url("~assets/fonts/BrandonTextF-Bold.ttf") format("truetype");
	font-display: swap;
}

@font-face {
	font-family: "Brandon Text";
	font-style: normal;
	font-weight: 500;
	src: url("~assets/fonts/BrandonText-Black.woff2") format("woff2"),
		url("~assets/fonts/BrandonText-Black.woff") format("woff"),
		url("~assets/fonts/BrandonText-Black.ttf") format("truetype");
	font-display: swap;
}
