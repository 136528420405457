.a-button {
	$root: &;
	min-width: 155px;
	height: 45px;
	padding: rem(4);
	font-family: $FONTFAMILY-BrandonText;
	font-weight: bold;
	line-height: normal;
	color: $COLOR-ARMOUR-TEXT;
	text-transform: uppercase;
	cursor: pointer;
	background: linear-gradient(90deg,
			rgb(224, 190, 96) -18.3%,
			rgb(229, 198, 109) 9.52%,
			rgb(241, 219, 142) 60.3%,
			rgb(252, 238, 171) 98.98%);
	border: 0;
	border-radius: rem(999);
	outline: none;
	box-shadow: 0 4px 4px rgba($color: $COLOR-BLACK, $alpha: 0.25);
	transition: opacity 0.3s ease-in-out;
	appearance: none;

	&:hover {
		@include pcFixedContent {
			opacity: 0.7;
		}
	}

	&:disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	&_wrapper:not(.a-button-otp) {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		padding: rem(4) rem(4) 0;
		overflow: hidden;
		background: radial-gradient(145.43% 145% at 50% 50%,
				rgba(255, 255, 255, 0.2) 0%,
				rgba(249, 219, 161, 0.576621) 7.14%,
				rgb(243, 179, 55) 26.79%,
				rgba(243, 179, 55, 0) 100%);

		border-radius: rem(999);

		// &::before {
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	height: 50%;
		// 	content: "";
		// 	background: linear-gradient(
		// 		180deg,
		// 		rgb(159, 184, 199) 1.73%,
		// 		rgb(1, 1, 1) 100.84%
		// 	);
		// 	mix-blend-mode: screen;
		// 	opacity: 0.8;

		// 	#{$root}-otp &,
		// 	#{$root}-textButton & {
		// 		display: none;
		// 	}
		// }

		#{$root}-otp &,
		#{$root}-textButton & {
			overflow: unset;
			line-height: 20px;
			background: unset;
			border-radius: unset;
		}

		#{$root}-textButton & {
			color: rgb(0, 0, 0);
		}

		#{$root}-secondary & {
			background: linear-gradient(359.42deg,
					rgb(118, 131, 121) 0.44%,
					rgb(55, 61, 58) 99.44%);
		}
	}

	&-linearPrimary {
		color: rgb(130, 40, 87);
		background: linear-gradient(90deg,
				rgb(200, 147, 59) 0%,
				rgb(250, 229, 158) 18%,
				rgb(214, 170, 102) 42%,
				rgb(229, 194, 124) 48%,
				rgb(240, 212, 142) 55%,
				rgb(247, 223, 153) 62%,
				rgb(249, 227, 156) 70%,
				rgb(240, 214, 135) 74%,
				rgb(221, 188, 91) 85%,
				rgb(209, 172, 64) 93%,
				rgb(205, 166, 54) 98%,
				rgb(205, 166, 54) 100%);

		#{$root}_wrapper {
			background: none;

			&::before {
				display: none;
			}
		}
	}

	&-yellowLinear {
		color: rgb(130, 40, 87);
		background: linear-gradient(90deg,
				rgb(200, 147, 59) 0%,
				rgb(250, 229, 158) 18%,
				rgb(214, 170, 102) 42%,
				rgb(229, 194, 124) 48%,
				rgb(240, 212, 142) 55%,
				rgb(247, 223, 153) 62%,
				rgb(249, 227, 156) 70%,
				rgb(240, 214, 135) 74%,
				rgb(221, 188, 91) 85%,
				rgb(209, 172, 64) 93%,
				rgb(205, 166, 54) 98%,
				rgb(205, 166, 54) 100%) !important;
	}

	&-otp {
		height: 43px;
		min-height: 42px;
		padding: 4px 8px;
		text-transform: none;
		background: linear-gradient(90deg,
				rgb(235, 175, 0) 20%,
				rgb(250, 210, 69) 60.3%,
				rgb(255, 213, 10) 98.98%);
		border-radius: 8px;

		@include sp {
			font-size: 10px;
			line-height: 17px;
		}
	}

	&-smallSize {
		min-width: 50px;
		padding: 0;
	}

	&-mediumSize {
		min-width: 125px;
	}

	&-fullWidth {
		width: 100%;
	}

	&-textButton {
		background: none;
		border-radius: 0;
		box-shadow: none;
	}

	&-secondary {
		background: none;
	}
}