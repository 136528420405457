.a-toastify {
	$root: &;
	$root-message: #{$root}_message;

	&_message {
		display: flex;
		align-items: center;
		padding: rem(10) rem(19.33) rem(10) rem(16);
		background: linear-gradient(
				104.31deg,
				rgb(0, 0, 0) -83.36%,
				rgb(39, 137, 252) 328.63%
			),
			rgb(196, 196, 196);

		&-has-description {
			align-items: flex-start;
			padding: rem(16) rem(19.33) rem(16) rem(16);
		}
	}

	&_sign {
		.a-icon {
			flex-shrink: 0;
		}
	}

	&_content {
		flex-grow: 1;
		margin: 0 rem(12);
		font-family: $FONTFAMILY-BASIC;
		color: $COLOR-WHITE;
	}

	&_description {
		margin-top: rem(4);
		font-size: rem(12);
		line-height: rem(18);
	}

	&_close {
		flex-shrink: 0;

		.a-icon {
			width: rem(20);
			height: rem(20);
		}
	}

	.Toastify {
		&__toast {
			min-height: unset;
			padding: 0;
			border-radius: rem(4);
			box-shadow: none;

			@include sp {
				margin-bottom: rem(5);
				border-radius: 0;
			}

			&-container {
				z-index: 10000;
				width: rem(340);
				padding: 0;

				@include sp {
					top: 0;
					right: unset;
					left: 0;
					width: 100%;
				}
			}
			&-body {
				padding: 0;
				cursor: default;
			}
		}
	}
}
