.a-heading {
	$root: &;
	position: relative;
	width: 100%;
	padding: 6px 5px;
	text-transform: uppercase;
	background: radial-gradient(
		145.43% 145% at 50% 50%,
		rgb(243, 179, 55) 26.79%,
		rgba(249, 219, 161, 0.576621) 7.14%,
		rgb(243, 179, 55) 26.79%,
		rgba(243, 179, 55, 0) 100%
	);
	border-radius: 5px;

	&_text {
		margin-top: 0;
		margin-bottom: 0;
		color: $COLOR-ARMOUR-TEXT;
		text-align: center;

		#{$root}-type-h2 & {
			font-size: rem(24);
			line-height: rem(32);
		}

		#{$root}-type-h3 & {
			font-size: rem(22);
			line-height: rem(28);
		}

		#{$root}-type-h4 & {
			font-size: rem(18);
			line-height: rem(24);
		}

		#{$root}-black & {
			color: rgb(0, 0, 0);
			text-shadow: unset;
		}
	}

	&-noBackground {
		background: unset;
	}

	&-yellowLinear {
		background: linear-gradient(
			90deg,
			rgb(200, 147, 59) 0%,
			rgb(250, 229, 158) 18%,
			rgb(214, 170, 102) 42%,
			rgb(229, 194, 124) 48%,
			rgb(240, 212, 142) 55%,
			rgb(247, 223, 153) 62%,
			rgb(249, 227, 156) 70%,
			rgb(240, 214, 135) 74%,
			rgb(221, 188, 91) 85%,
			rgb(209, 172, 64) 93%,
			rgb(205, 166, 54) 98%,
			rgb(205, 166, 54) 100%
		);

		#{$root}_text {
			color: rgb(130, 40, 87);
			text-shadow: none;
		}
	}
}
