.m-billSelect {
	.a-image {
		border-radius: rem(8);
	}

	&_radioWrapper {
		display: flex;
		justify-content: center;
		margin-top: rem(12);
	}

	&-used {
		position: relative;

		&::after {
			position: absolute;
			top: 40%;
			left: 50%;
			width: 100%;
			font-size: rem(26);
			text-align: center;
			content: "Đã sử dụng";
			transform: translate(-50%, -50%);
		}

		.a-image {
			&::after {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				content: "";
				background-color: rgba(#000, 0.6);
			}
		}
	}
}
