.t-scanQr {
	position: relative;
	flex-direction: column;
	max-width: rem(500);
	height: 90vh;
	margin-right: auto;
	margin-left: auto;
	overflow: hidden;
	background-color: rgb(0, 0, 0);
	@include adjust-flex(center, center);

	@include mobile-down {
		min-height: rem(550);
	}

	&_background {
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
		}
	}

	&_img {
		max-width: rem(220);
		margin: 0 auto;

		img {
			width: 100%;
		}
	}

	&_content {
		flex-direction: column;
		width: 100%;
		color: var(--text-color);
		text-align: center;
		@include adjust-flex(center, center);

		strong {
			margin-top: rem(24);
			@include font-base(16, 24);
		}

		p {
			margin-top: rem(8);
			@include font-base(14, 20);
		}
	}

	&_input {
		width: 80%;
		margin-bottom: rem(24);
	}

	&_button {
		.a-button {
			margin-right: rem(4);
			margin-left: rem(4);
		}
	}
}

.t-qrPopup {
	&_video {
		position: relative;
		max-width: rem(320);
		padding: rem(24);
		margin: auto;
		overflow: hidden;
		background: transparent url("~assets/images/qr-border.svg") no-repeat
			center/100% auto;
		border-radius: rem(28);

		&::before {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 20;
			width: 100%;
			height: rem(72);
			content: "";
			background: transparent url("~assets/images/scan-bar.svg") no-repeat
				center/100% auto;
			transition: 0.4s ease;
			animation: moveScan 2s infinite;
		}

		.wrap {
			position: relative;
			width: 100%;
			padding-bottom: 100%;
			overflow: hidden;
			border-radius: rem(28);
			transition: 0.4s ease;
		}

		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	&_button {
		margin-top: rem(20);
	}

	p {
		margin-top: rem(34);
		font-size: 16px;
		line-height: 19px;
		color: var(--text-color);
		text-align: center;
	}
}

.t-infoPopup {
	&_name {
		font-weight: 600;
		color: $MEDIUM-SEA-GREEN;
		text-align: center;
		text-transform: uppercase;
		@include font-base(20, 27);
	}

	&_info {
		margin-top: rem(16);

		ul {
			padding-left: 0;
			text-align: center;
			list-style: none;

			li {
				margin-bottom: rem(12);
				color: $JET;
				@include font-base(16, 21);
			}

			b {
				font-weight: 700;
				color: $MEDIUM-SEA-GREEN;
			}
		}
	}
}

@keyframes moveScan {
	0% {
		top: 24px;
	}

	50% {
		top: calc(100% - 92px);
	}

	100% {
		top: 24px;
	}
}
