.a-checkbox {
	$root: &;
	display: block;

	&_input {
		position: absolute;
		pointer-events: none;
		opacity: 0;
	}

	&_label {
		display: flex;
		align-items: center;
		margin-bottom: 0;
	}

	&_body {
		position: relative;
		display: flex;
		flex: 0 0 auto;
		align-items: center;
		justify-content: center;
		width: rem(20);
		height: rem(20);
		cursor: pointer;
		background: radial-gradient(rgb(255, 255, 255) 0%, rgb(245, 245, 245) 100%);
		border: 1px solid $COLOR-ARMOUR;
		border-radius: 50%;
		transition: 0.3s ease-in-out;

		#{$root}_input:hover + & {
			opacity: 0.8;
		}
	}

	&_inner {
		position: absolute;
		top: -1px;
		left: 6px;
		width: 8px;
		height: 14px;
		border-right: 3px solid transparent;
		border-bottom: 3px solid transparent;
		transition: all 0.2s ease-in-out;
		transform: rotate(45deg);

		#{$root}_input:checked + #{$root}_body > & {
			border-color: $COLOR-ARMOUR;
		}
	}

	&_content {
		margin-left: rem(8);
		line-height: rem(24);
		color: rgb(255, 255, 255);

		@include sp {
			font-size: 14px;
			line-height: 18px;
		}

		a {
			color: inherit;
			text-decoration: underline;
		}
	}
}
